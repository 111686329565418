<template>
  <div class="colorStyleBox">
    <div class="colorConfig">
      <p>选择配色方案</p>
      <div class="colorList">
        <div :class="['listItem', indexItem == index ? 'activeitem' : '']" @click="chooseItem(item, index)" v-for="(item, index) in colorArr" :key="index">
          <div :style="{ background: item.main_color }"></div>
          <div :style="{ background: item.secondary_color }"></div>
        </div>
      </div>
    </div>
    <div class="examples">
      <p>当前风格预览</p>
      <div class="examplesList">
        <div class="collect">
          <div class="price" :style="{ color: activeColor.main_color }">￥<span style="font-size: 18px">369.9</span></div>
          <div class="oncePay">
            <div :style="{ background: activeColor.secondary_color, width: '110px', color: activeColor.is_matchs == 1 ? activeColor.main_color : '' }">加入购物车</div>
            <div :style="{ background: activeColor.main_color, width: '109px' }">立即购买</div>
          </div>
        </div>
        <div class="chooseSize">
          <div class="price" :style="{ color: activeColor.main_color }">￥<span style="font-size: 18px">369.9</span></div>
          <div class="chooseSstyle" :style="{ background: activeColor.secondary_color, color: activeColor.is_matchs == 1 ? activeColor.main_color : '#fff' }">蓝白条纹</div>
          <div class="size" :style="{ background: activeColor.secondary_color, color: activeColor.is_matchs == 1 ? activeColor.main_color : '#fff' }">M</div>
          <div class="oncePay">
            <div :style="{ background: activeColor.secondary_color, width: '140px', color: activeColor.is_matchs == 1 ? activeColor.main_color : '' }">加入购物车</div>
            <div :style="{ background: activeColor.main_color, width: '139px' }">立即购买</div>
          </div>
        </div>
        <div class="order">
          <div class="subtotal" :style="{ color: activeColor.main_color }">￥<span style="font-size: 18px">327.9</span></div>
          <div class="alltotal" :style="{ color: activeColor.main_color }">￥<span style="font-size: 18px">327.9</span></div>
          <div class="submit" :style="{ background: activeColor.main_color }">提交订单</div>
        </div>
      </div>
    </div>
    <!-- <el-button type="primary" @click="preservation">保存</el-button> -->
    <Preservation :addPage="$route.path === '/addPages/colorStyle' ? 1 : ''" @preservation="preservation"></Preservation>
  </div>
</template>

<script>
import Preservation from '@/components/preservation'
export default {
  components: {
    Preservation
  },
  data() {
    return {
      activeColor: {},
      colorArr: [],
      indexItem: 0,
      id: -1
    }
  },
  computed: {
    shopInfo() {
      return this.$store.getters.shopInfo
    }
  },
  created() {
    this.$store.dispatch('user/getShopInfo')
    let that = this
    this.$axios.post(this.$api.shopDiy.ShopColor, {}).then((res) => {
      if (res.code == 0) {
        that.colorArr = res.result
        that.$nextTick(() => {
          setTimeout(() => {
            that.indexItem = that.colorArr.findIndex((item) => item.id == that.shopInfo.color_scheme)
            if (that.indexItem == -1) {
              that.activeColor = that.colorArr[0]
            } else {
              that.activeColor = that.colorArr[that.indexItem]
            }
          }, 100)
        })
      }
    })
  },
  methods: {
    chooseItem(item, index) {
      this.indexItem = index
      this.activeColor = item
    },
    preservation() {
      let that = this
      this.$axios
        .post(this.$api.shopDiy.setBase, {
          color_scheme: that.colorArr[that.indexItem].id
        })
        .then((res) => {
          if (res.code == 0) {
            that.$message({
              message: '设置颜色配置成功',
              type: 'success'
            })
            return
          }
          that.$message({
            message: '设置颜色配置失败',
            type: 'error'
          })
        })
    }
  }
}
</script>

<style lang="less" scoped>
.colorStyleBox {
  height: 100%;
  background-color: #fff;
  padding: 50px 0 0 50px;
  font-size: 14px;
  overflow-y: auto;
  .colorConfig {
    display: flex;
    margin-bottom: 40px;
    p {
      width: 100px;
      text-align: right;
      margin-right: 20px;
      line-height: 30px;
    }
    .colorList {
      width: 1000px;
      display: flex;
      flex-wrap: wrap;
      .listItem {
        width: 100px;
        display: flex;
        align-items: center;
        margin: 0 20px 20px 0;
        & > div {
          width: 50%;
          height: 20px;
        }
      }
      .activeitem {
        position: relative;
        &::after {
          content: '';
          width: calc(100% + 6px);
          height: calc(100% + 6px);
          position: absolute;
          top: -5px;
          left: -5px;
          border: 2px solid #409eff;
        }
      }
    }
  }
  .examples {
    display: flex;
    margin-bottom: 20px;
    p {
      width: 100px;
      text-align: right;
      margin-right: 20px;
      line-height: 30px;
    }
    .examplesList {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      & > div {
        width: 375px;
        height: 667px;
        margin-right: 20px;
        background-size: 100% 100%;
        border: 1px solid #ccc;
        position: relative;
        transform: scale(0.8) translate(-45px, -70px);
      }
      .collect {
        background-image: url('https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/collect1.png');
        .price {
          font-size: 12px;
          font-weight: bold;
          position: absolute;
          top: 460px;
          left: 23px;
        }
        .oncePay {
          width: 220px;
          height: 40px;
          border-radius: 20px;
          overflow: hidden;
          display: flex;
          justify-content: space-between;
          position: absolute;
          bottom: 10px;
          right: 12px;
          & > div {
            text-align: center;
            line-height: 40px;
            font-size: 12px;
            font-weight: bold;
            color: #fff;
          }
        }
      }
      .chooseSize {
        transform: scale(0.8) translate(-105px, -70px);
        background-image: url('https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chooseSize1.png');
        .price {
          font-size: 12px;
          font-weight: bold;
          position: absolute;
          top: 252px;
          left: 114px;
        }
        .chooseSstyle {
          width: 70px;
          height: 26px;
          border-radius: 8px;
          font-size: 12px;
          text-align: center;
          line-height: 28px;
          position: absolute;
          top: 359px;
          left: 16px;
        }
        .size {
          width: 40px;
          height: 26px;
          font-size: 12px;
          border-radius: 8px;
          text-align: center;
          line-height: 28px;
          position: absolute;
          top: 442px;
          left: 73px;
        }

        .oncePay {
          width: 280px;
          height: 44px;
          border-radius: 22px;
          overflow: hidden;
          display: flex;
          justify-content: space-between;
          position: absolute;
          bottom: 10px;
          left: 50%;
          transform: translateX(-50%);
          & > div {
            text-align: center;
            line-height: 44px;
            font-size: 12px;
            font-weight: bold;
            color: #fff;
          }
        }
      }
      .order {
        transform: scale(0.8) translate(-165px, -70px);
        background-image: url('https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/orderLast1.png');
        .subtotal {
          font-size: 16px;
          font-weight: 500;
          position: absolute;
          right: 27px;
          top: 482px;
        }
        .alltotal {
          font-size: 14px;
          font-weight: 500;
          position: absolute;
          bottom: 29px;
          left: 94px;
        }
        .submit {
          width: 120px;
          height: 44px;
          border-radius: 22px;
          text-align: center;
          line-height: 44px;
          font-size: 14px;
          color: #fff;
          position: absolute;
          bottom: 18px;
          right: 16px;
        }
      }
    }
  }
  .el-button {
    margin-left: 170px;
  }
}
</style>
